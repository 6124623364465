import axios from 'axios';
import querystring from 'query-string';
import urls from './urls';

/**
 * INVOICE ORACLE
 * @param {String<REGULAR|FIXED_PRICE>} type selection list filter
 */

const getListInvoiceOracle = async (filter, type, source = {}) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/filter/${type}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailInvoiceOracle = async (id) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendInvoiceOracle = async (id) => {
  try {
    const res = await axios.patch(`${urls.oracleInvoice}/resend/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * ACCRUAL GL
 * @param {String<ONCALL|FIXED_PRICE>} type selection list filter
 */

const getListAccrualGl = async (filter, type, source = {}) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/filter/${type}?${querystring.stringify(filter)}`, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailAccrualGl = async (id) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendAccrualGl = async (id) => {
  try {
    const res = await axios.patch(`${urls.accrualGl}/resend/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLocationOracle = async ({ filter, companyId }) => {
  try {
    const res = await axios.get(`${urls.master}/oracle-location/filter/${companyId}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const fetchCsvTemplateData = async () => {
  try {
    const res = await axios.get(`${urls.csvTemplateDataLocationOracle}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const uploadLocationOracle = async ({ formData, companyId }) => {
  try {
    const res = await axios.post(`${urls.uploadCsvLocationOracle}/${companyId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateLocationOracleById = async ({ oracleLocationId, fastlogLocationId }) => {
  try {
    const res = await axios.patch(`${urls.master}/oracle-location/${oracleLocationId}/${fastlogLocationId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListOrcleOrders = async ({ filter, status }) => {
  try {
    const res = await axios.get(`${urls.shipmentOrder}/${status}?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListDetailOrcleOrders = async ({ filter, status }) => {
  try {
    const res = await axios.get(`${urls.shipmentOrder}/${status}/detail?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const refreshOrcleOrders = async () => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/refresh`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getOrcleOrdersDetail = async ({ groupId }) => {
  try {
    const res = await axios.get(`${urls.shipmentOrder}/detail/${groupId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const combineShipment = async ({ groupId, isCombine }) => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/${groupId}?editableFlag=${isCombine}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateOrcaleShipment = async ({ groupId, form, source = {} }) => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/${groupId}/route`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const updateCompletedOrcaleShipment = async ({ groupId, form, source = {} }) => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/${groupId}/shipment`, form, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getLogOracleOrders = async ({ groupId }) => {
  try {
    const res = await axios.get(`${urls.shipmentOrder}/log/${groupId}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const resendLogOracleOrders = async ({ groupId, source }) => {
  try {
    const res = await axios.patch(`${urls.shipmentOrder}/resend/${groupId}`, {}, {
      cancelToken: source.token,
    });
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAccrualOracle = async ({ filter }) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/oracle/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailAccrualOracle = async ({ id }) => {
  try {
    const res = await axios.get(`${urls.accrualGl}/oracle/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListInvoiceFromOracle = async ({ filter }) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/oracle/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailInvoiceFromOracle = async ({ id }) => {
  try {
    const res = await axios.get(`${urls.oracleInvoice}/oracle/detail?invoiceFromOracleId=${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getListAccrualFromOracle = async ({ filter }) => {
  try {
    const res = await axios.get(`${urls.accrualOracle}/filter?${querystring.stringify(filter)}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

const getDetailAccrualFromOracle = async ({ id }) => {
  try {
    const res = await axios.get(`${urls.accrualOracle}/${id}`);
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  getListInvoiceOracle,
  resendInvoiceOracle,
  getDetailInvoiceOracle,
  getListAccrualGl,
  getDetailAccrualGl,
  resendAccrualGl,
  getLocationOracle,
  uploadLocationOracle,
  fetchCsvTemplateData,
  updateLocationOracleById,
  getListOrcleOrders,
  getListDetailOrcleOrders,
  getOrcleOrdersDetail,
  combineShipment,
  updateOrcaleShipment,
  updateCompletedOrcaleShipment,
  getListAccrualOracle,
  getDetailAccrualOracle,
  getListInvoiceFromOracle,
  getDetailInvoiceFromOracle,
  getLogOracleOrders,
  resendLogOracleOrders,
  getListAccrualFromOracle,
  getDetailAccrualFromOracle,
  refreshOrcleOrders,
};

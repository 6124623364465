/* eslint-disable no-restricted-globals */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-named-as-default-member */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import * as Strings from '../assets/strings';
// import store from '../store';

const Welcome = () => import(/* webpackChunkName: "view-Welcome" */ '../views/Welcome.vue');
const Login = () => import(/* webpackChunkName: "component-Login" */ '../components/Welcome/Login.vue');
const EmailVerification = () => import(
  /* webpackChunkName: "component-EmailVerification" */ '../components/Welcome/EmailVerification.vue'
);
const ActivationAccount = () => import(
  /* webpackChunkName: "component-ActivationAccount" */ '../components/Welcome/Activation.vue'
);
const ResetPassword = () => import(
  /* webpackChunkName: "component-ResetPassword" */ '../components/Welcome/ResetPassword.vue'
);
const ChangePassword = () => import(
  /* webpackChunkName: "component-ChangePassword" */ '../components/Welcome/ChangePassword.vue'
);

const MainContainer = () => import(/* webpackChunkName: "view-MainContainer" */ '../views/MainContainer');

const AboutPage = () => import(/* webpackChunkName: "view-AboutPage" */ '../views/About');
const ContactPage = () => import(/* webpackChunkName: "view-ContactPage" */ '../views/ContactUs');
const PrivacyPage = () => import(/* webpackChunkName: "view-PrivacyPage" */ '../views/PrivacyPolicy');

const DashboardPage = () => import(/* webpackChunkName: "view-DashboardPage" */ '../views/Dashboard');
const DashboardList = () => import(/* webpackChunkName: "component-DashboardList" */ '../components/Dashboard');

const ShipmentsPage = () => import(/* webpackChunkName: "view-ShipmentsPage" */ '../views/Shipments');

// Master Sales
const SalesPage = () => import(/* webpackChunkName: "view-SalesPage" */ '../views/Sales');
const MasterSalesList = () => import(/* webpackChunkName: "component-MasterSalesList" */ '../components/MasterSales');

const SelectionInfoList = () => import(/* webpackChunkName: "component-SelectionInfoList" */ '../components/SelectionInfo');
const SelectionInfo = () => import(/* webpackChunkName: "view-SelectionInfo" */ '../views/SelectionInfo');
const Parameter = () => import(/* webpackChunkName: "view-Parameter" */ '../views/Parameter.vue');
const ParameterList = () => import(/* webpackChunkName: "component-ParameterList" */ '../components/Parameter/List.vue');
const CategoriesPage = () => import(/* webpackChunkName: "view-CategoriesPage" */ '../views/Categories');
const CategoryList = () => import(/* webpackChunkName: "component-CategoryList" */ '../components/Category/List');
const CategoryCreate = () => import(/* webpackChunkName: "component-CategoryCreate" */ '../components/Category/Create');

const LocationsPage = () => import(/* webpackChunkName: "view-LocationsPage" */ '../views/Locations.vue');
const LocationList = () => import(/* webpackChunkName: "component-LocationList" */ '../components/Locations');
const LocationCreate = () => import(/* webpackChunkName: "component-LocationCreate" */ '../components/Locations/Create');

const UserAdminPage = () => import(/* webpackChunkName: "view-UserAdminPage" */ '../views/UserAdmin.vue');
const UserAdminList = () => import(/* webpackChunkName: "component-UserAdminList" */ '../components/UserAdmin');
const UserAdminCreate = () => import(/* webpackChunkName: "component-UserAdminCreate" */ '../components/UserAdmin/Create');

const TransportTypesPage = () => import(/* webpackChunkName: "view-TransportTypesPage" */ '../views/TransportTypes.vue');
const TransportTypesList = () => import(/* webpackChunkName: "component-TransportTypesList" */ '../components/TransportTypes');
const TransportTypesCreate = () => import(
  /* webpackChunkName: "component-TransportTypesCreate" */ '../components/TransportTypes/Create.vue'
);

const LegalDocumentPage = () => import(/* webpackChunkName: "view-LegalDocumentPage" */ '../views/LegalDocument.vue');
const LegalDocumentList = () => import(
  /* webpackChunkName: "component-LegalDocumentList" */ '../components/LegalDocument/List.vue'
);

// ORDER
const OrderPage = () => import(/* webpackChunkName: "view-OrderPage" */ '../views/Orders.vue');
const UpdateOrdersPage = () => import(/* webpackChunkName: "view-UpdateOrdersPage" */ '../views/UpdateOrders.vue');
const OrderList = () => import(/* webpackChunkName: "component-OrderList" */ '../components/Orders');
const OrderDetail = () => import(/* webpackChunkName: "component-OrderDetail" */ '../components/Orders/OrderDetails');
const MultimodaBasp = () => import(/* webpackChunkName: "component-MultimodaBasp" */ '../components/Orders/MultimodaBasp');
const OrderHistories = () => import(/* webpackChunkName: "component-OrderHistories" */ '../components/Orders/OrderHistories');

// MANUAL ORDER
const ManualOrdersPage = () => import(/* webpackChunkName: "view-ManualOrderPage" */ '../views/ManualOrders.vue');
const ManualOrder = () => import(
  /* webpackChunkName: "component-MainManualOrderPage" */ '../components/ManualOrders'
);
const CreateManualOrder = () => import(
  /* webpackChunkName: "component-CreateManualOrderPage" */ '../components/ManualOrders/Order/Create'
);

// CONTRACT
const ContractShipperPage = () => import(/* webpackChunkName: "view-ContractShipperPage" */ '../views/ContractShipper');
const Contract3PLPage = () => import(/* webpackChunkName: "view-Contract3PLPage" */ '../views/Contract3PL');
const TerminationContractPage = () => import(/* webpackChunkName: "view-TerminationContractPage" */ '../views/TerminationContract');
const AdendumContractPage = () => import(/* webpackChunkName: "view-AdendumContractPage" */ '../views/AdendumContract');

// CONTRACT
const ContractList = () => import(/* webpackChunkName: "component-ContractList" */ '../components/Contract');
const ContractForm = () => import(/* webpackChunkName: "component-ContractForm" */ '../components/Contract/Form');
const ContractFormTermination = () => import(
  /* webpackChunkName: "component-ContractFormTermination" */ '../components/Contract/FormTerminationContract'
);
const TemplateContract = () => import(
  /* webpackChunkName: "component-TemplateContract" */ '../components/Contract/TemplateContract.vue'
);
const TemplateAdendum = () => import(
  /* webpackChunkName: "component-TemplateAdendum" */ '../components/Contract/TemplateAdendum.vue'
);
const ContractInProcess = () => import(/* webpackChunkName: "view-ContractInProcess" */ '../views/ContractInProcess.vue');

// Schedule
const SchedulePage = () => import(/* webpackChunkName: "view-SchedulePage" */ '../views/Schedule');
const Schedule = () => import(/* webpackChunkName: "view-ScheduleComponent" */ '../components/Schedule');

// MoU
const MoUPage = () => import(/* webpackChunkName: "view-MoUPage" */ '../views/MoU.vue');
const MoUList = () => import(/* webpackChunkName: "component-MoUList" */ '../components/MoU/List.vue');
const MoUCreate = () => import(/* webpackChunkName: "component-MoUCreate" */ '../components/MoU/Create');

// Additional Cost
const AdditionalCost = () => import(/* webpackChunkName: "view-AdditionalCost" */ '../views/AdditionalCost.vue');
const AdditionalCostList = () => import(/* webpackChunkName: "component-AdditionalCostList" */ '../components/AdditionalCost');

// acoute Cost
const AcouteCost = () => import(/* webpackChunkName: "view-acouteCost" */ '../views/AcouteCost.vue');
const AcouteCostList = () => import(/* webpackChunkName: "component-acouteCostList" */ '../components/AcouteCost');

// Approval
const InboxApprovalPage = () => import(/* webpackChunkName: "view-InboxApprovalPage" */ '../views/ApprovalInbox.vue');
const InboxApprovalList = () => import(/* webpackChunkName: "component-InboxApprovalList" */ '../components/Approval/Inbox');
const ApprovalInProcessPage = () => import(
  /* webpackChunkName: "component-ApprovalInProcessPage" */ '../views/ApprovalInProcess.vue'
);
const ApprovalInProcessList = () => import(
  /* webpackChunkName: "component-ApprovalInProcessList" */ '../components/Approval/InProcess'
);
const CompletedApprovalPage = () => import(/* webpackChunkName: "component-CompletedApprovalPage" */ '../views/CompleteApproval.vue');
const CompletedApprovalList = () => import(
  /* webpackChunkName: "component-CompletedApprovalList" */ '../components/Approval/Completed'
);
const InboxMouInProcess = () => import(/* webpackChunkName: "view-InboxMouInProcess" */ '../views/MouInProcess.vue');
// Parameter
const ListUpdateParameter = () => import(/* webpackChunkName: "component-CustomerList" */ '../components/Parameter/ListUpdate.vue');
const EditDataParameter = () => import(/* webpackChunkName: "component-CustomerList" */ '../components/Parameter/EditData.vue');
const FormRegistration = () => import(
  /* webpackChunkName: "component-CustomerList" */ '../components/FormRegistration/FormRegistration.vue'
);

// Company Registration
const CompanyRegistrationPage = () => import(/* webpackChunkName: "view-CompanyRegistrationPage" */ '../views/CompanyRegistration.vue');

// Invoice Data
const InvoiceDataPage = () => import(/* webpackChunkName: "view-InvoiceDataPage" */ '../views/InvoiceData.vue');
const InvoiceDataList = () => import(/* webpackChunkName: "component-InvoiceDataList" */ '../components/InvoiceData/List.vue');

// Eta
const EtaDataPage = () => import(/* webpackChunkName: "view-EtaDataPage" */ '../views/Eta.vue');
const EtaDataList = () => import(/* webpackChunkName: "component-EtaDataList" */ '../components/Eta/List.vue');
const EtaDataCreate = () => import(/* webpackChunkName: "component-EtaDataCreate" */ '../components/Eta/Create.vue');

// Dokumen Pod
const DokumenPodPage = () => import(/* webpackChunkName: "view-DokumenPodPage" */ '../views/DokumenPod.vue');
const DokumenPodList = () => import(/* webpackChunkName: "component-DokumenPodList" */ '../components/DokumenPod/List.vue');
const DokumenPodCreate = () => import(
  /* webpackChunkName: "component-DokumenPodCreate" */ '../components/DokumenPod/Create.vue'
);

// Shipping Cost
const ShippingCost = () => import(/* webpackChunkName: "view-ShippingCost" */ '../views/ShippingCost.vue');
const ShippingCostList = () => import(
  /* webpackChunkName: "component-ShippingCostList" */ '../components/ShippingCost/List.vue'
);

// Email
const Email = () => import(/* webpackChunkName: "view-Email" */ '../views/Email.vue');
const EmailList = () => import(/* webpackChunkName: "component-EmailList" */ '../components/Email/List.vue');

// Invoice3PL
const Invoice3PL = () => import(/* webpackChunkName: "view-Invoice3PL" */ '../views/Invoice3PL.vue');
const InvoiceList = () => import(/* webpackChunkName: "component-InvoiceList" */ '../components/Invoice3PL/List.vue');

// InvoiceShipper
const InvoiceShipper = () => import(/* webpackChunkName: "view-InvoiceShipper" */ '../views/InvoiceShipper.vue');
const InvoiceShipperList = () => import(
  /* webpackChunkName: "component-InvoiceShipperList" */ '../components/InvoiceShipper/List.vue'
);
const InvoiceCreate = () => import(
  /* webpackChunkName: "component-InvoiceCreate" */ '../components/InvoiceShipper/Create.vue'
);
const InvoiceEdit = () => import(/* webpackChunkName: "component-InvoiceEdit" */ '../components/InvoiceShipper/Edit.vue');

// Invoice fixed price
const InvoiceFixedPrice = () => import(/* webpackChunkName: "view-InvoiceFixedPrice" */ '../views/InvoiceFixedPrice.vue');
const InvoiceFixedPriceList = () => import(
  /* webpackChunkName: "compoenent-InvoiceFixedPriceList" */ '../components/InvoiceFixedPrice/List.vue'
);
const InvoiceFixedPriceListDetail = () => import(
  /* webpackChunkName: "compoenent-InvoiceFixedPriceListDetail" */ '../components/InvoiceFixedPrice/ListDetail.vue'
);
const InvoiceFixedPriceListPriceDetail = () => import(
  /* webpackChunkName: "compoenent-InvoiceFixedPriceListPriceDetail" */ '../components/InvoiceFixedPrice/ListPriceDetailOrder.vue'
);

// invoice customer cancel
const InvoiceCustomerCancel = () => import(/* webpackChunkName: "view-InvoiceCustomerCancel" */ '../views/InvoiceCustomerCancel.vue');
const InvoiceCustomerCancelList = () => import(/* webpackChunkName: "component-InvoiceCustomerCancelList" */ '../components/InvoiceCustomerCancel/List/index.vue');
const InvoiceCustomerCancelDetail = () => import(/* webpackChunkName: "component-InvoiceCustomerCancelDetail" */ '../components/InvoiceCustomerCancel/Detail/index.vue');
const InvoiceCustomerCancelCreate = () => import(/* webpackChunkName: "component-InvoiceCustomerCancelCreate" */ '../components/InvoiceCustomerCancel/Create/index.vue');

// invoice fixed price customer cancel
const InvoiceFixedPriceCustomerCancel = () => import(/* webpackChunkName: "view-InvoiceFixedPriceCustomerCancel" */ '../views/InvoiceFixedPriceCustomerCancel.vue');
const InvoiceFixedPriceCustomerCancelList = () => import(/* webpackChunkName: "component-InvoiceFixedPriceCustomerCancel" */ '../components/InvoiceFixedPriceCustomerCancel/List/index.vue');
const InvoiceFixedPriceCustomerCancelDetail = () => import(/* webpackChunkName: "component-InvoiceFixedPriceCustomerCancelDetail" */ '../components/InvoiceFixedPriceCustomerCancel/Detail');

// Vaccine
const Vaccine = () => import(/* webpackChunkName: "view-Vaccine" */ '../views/Vaccine.vue');
const VaccineList = () => import(/* webpackChunkName: "component-VaccineList" */ '../components/Vaccine/List.vue');

// Vaccine
const AreaCode = () => import(/* webpackChunkName: "view-Vaccine" */ '../views/AreaCode.vue');
const AreaCodeList = () => import(/* webpackChunkName: "component-VaccineList" */ '../components/AreaCode/List.vue');

// Damage And Missing
const DamageAndMissing = () => import(/* webpackChunkName: "view-DamageAndMissing" */ '../views/DamageAndMissing.vue');
const DamageAndMissingList = () => import(/* webpackChunkName: "component-DamageAndMissingList" */ '../components/DamageAndMissing');

// Shipping-problem
const ShippingProblemPage = () => import(/* webpackChunkName: "view-ShippingProblemPage" */ '../views/ShippingProblem.vue');
const ShippingProblemList = () => import(/* webpackChunkName: "component-ShippingProblemList" */ '../components/ShippingProblem');
const ShippingProblemDetail = () => import(
  /* webpackChunkName: "component-ShippingProblemDetail" */ '../components/ShippingProblem/ShippingProblemDetail.vue'
);

// Master Customer
const MasterCustomerPage = () => import(
  /* webpackChunkName: "view-MasterCustomerPage" */ '../views/MasterCustomer.vue'
);
const MasterCustomerList = () => import(
  /* webpackChunkName: "component-MasterCustomerList" */ '../components/MasterCustomer/List.vue'
);

// Master Partner And Master Shipper
const MasterPartnerAndShipperPage = () => import(
  /* webpackChunkName: "view-MasterPartnerAndShipperPage" */ '../views/MasterPartnerAndShipper.vue'
);
const MasterPartnerAndShipperList = () => import(
  /* webpackChunkName: "component-MasterPartnerAndShipperList" */ '../components/MasterPartnerAndShipper/List.vue'
);
const MasterPartnerAndShipperDetail = () => import(
  /* webpackChunkName: "component-MasterPartnerAndShipperDetail" */ '../components/MasterPartnerAndShipper/DetailsPage.vue'
);

// Tax
const TaxPage = () => import(/* webpackChunkName: "view-TaxPage" */ '../views/Tax.vue');
const TaxList = () => import(/* webpackChunkName: "component-TaxList" */ '../components/Tax/List.vue');

// AccountInfo
const AccountInfo = () => import(/* webpackChunkName: "view-AccountInfo" */ '../views/AccountInfo.vue');
const AccountInfoGet = () => import(
  /* webpackChunkName: "component-AccountInfoGet" */ '../components/AccountInformation/List.vue'
);

// Term Of Payment
const TopPage = () => import(/* webpackChunkName: "view-TopPage" */ '../views/TermOfPayment.vue');
const TopList = () => import(/* webpackChunkName: "component-TopList" */ '../components/TermOfPayment/List.vue');
const TopCreate = () => import(/* webpackChunkName: "component-TopCreate" */ '../components/TermOfPayment/Create.vue');

// Report
const ReportPage = () => import(/* webpackChunkName: "view-ReportPage" */ '../views/Report.vue');
const ReportList = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report');
const ReportFiltres = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/ReportFilters');
const ReportDetail = () => import(/* webpackChunkName: "component-ReportList" */ '../components/Report/Detail');

// Wording
const Wording = () => import(/* webpackChunkName: "view-Wording" */ '../views/Wording.vue');
const WordingList = () => import(/* webpackChunkName: "component-WordingList" */ '../components/Wording/List.vue');
const EditWording = () => import(/* webpackChunkName: "component-EditWording" */ '../components/Wording/EditWording.vue');

// P2P Lending
const PageP2PLending = () => import(/* webpackChunkName: "page-p2p-lending" */ '../views/P2PLending.vue');
const ListP2PLending = () => import(/* webpackChunkName: "list-p2p-lending" */ '../components/P2PLending/List.vue');
const CreateP2PLending = () => import(/* webpackChunkName: "create-p2p-lending" */ '../components/P2PLending/Create.vue');

// Holiday
const PageHoliday = () => import(/* webpackChunkName: "page-holiday" */'../views/Holiday.vue');
const HolidayList = () => import(/* webpackChunkName: "component-holiday" */ '../components/Holiday/List.vue');

// Close Periode
const PageClosePeriode = () => import(/* webpackChunkName: "page-close-period" */'../views/ClosePeriod.vue');
const ClosePeriodeList = () => import(/* webpackChunkName: "component-periode" */ '../components/ClosePeriod/List.vue');
const SettingClosePeriode = () => import(/* webpackChunkName: "component-create-periode" */ '../components/ClosePeriod/SettingClosePeriod.vue');

// Oracle Cost
const PageOracleCost = () => import(/* webpackChunkName: "page-oracle-cost" */'../views/OracleCost.vue');

// oracle data
const PageOracleInvoice = () => import(/* webpackChunkName: "page-oracle-invoice" */ '../views/OracleInvoice');
const OracleInvoice = () => import(/* webpackChunkName: "component-oracle-invoice" */ '../components/OracleInvoice');
const PageOracleAccrualGL = () => import(/* webpackChunkName: "page-oracle-accrual-gl" */ '../views/OracleAccrualGL');
const OracleAccrualGL = () => import(/* webpackChunkName: "component-oracle-accrual-gl" */ '../components/OracleAccrualGL');

// location oracle
const PageOracleLocation = () => import(/* webpackChunkName: "page-oracle-location-invoice" */ '../views/OracleLocation');
const ListOracleLocation = () => import(/* webpackChunkName: "component-list-oracle-location" */ '../components/OracleLocation/List.vue');

// Role
const PageRole = () => import(/* webpackChunkName: "page-role" */ '../views/Role');
const ListRole = () => import(/* webpackChunkName: "component-list-role" */ '../components/Role');
const DetailRole = () => import(/* webpackChunkName: "component-detail-role" */ '../components/Role/DetailRole.vue');

// Master Menu
const PageMasterMenu = () => import(/* webpackChunkName: "page-master-menu" */ '../views/MasterMenu');
const ListMasterMenu = () => import(/* webpackChunkName: "component-list-master-menu" */ '../components/MasterMenu');

// Oracle Orders
const PageOracleOrders = () => import(/* webpackChunkName: "page-oracle-orders" */ '../views/OracleOrders');
const ListOracleOrders = () => import(/* webpackChunkName: "component-list-oracle-orders" */ '../components/OracleOrders');

// Rejected Orders
const PageRejectedOrders = () => import(/* webpackChunkName: "page-rejected-orders" */ '../views/RejectedOrders');
const ListRejectedOrders = () => import(/* webpackChunkName: "component-list-rejected-orders" */ '../components/RejectedOrders');

// Empty Page
const PageDontHavePermission = () => import(/* webpackChunkName: "page-empty-page" */ '../views/EmptyPage.vue');

// Transport
const TransportPage = () => import('../views/Transport.vue');
const TransportList = () => import('../components/Transport/List.vue');
const TransportCreate = () => import('../components/Transport/Create.vue');

// Driver
const DriverPage = () => import('../views/Driver.vue');
const DriverList = () => import('../components/Driver/List.vue');
const DriverCreate = () => import('../components/Driver/Create.vue');

// Accrual Oracle
const AccrualOraclePage = () => import('../views/AccrualOracle.vue');
const AccrualOracleList = () => import('../components/AccrualOracle');

// Invoice From Oracle
const InvoiceFromOraclePage = () => import('../views/InvoiceFromOracle.vue');
const InvoiceFromOracleList = () => import('../components/InvoiceFromOracle');

// Accrual From Oracle
const AccrualFromOraclePage = () => import('../views/AccrualFromOracle.vue');
const AccrualFromOracleList = () => import('../components/AccrualFromOracle');

// Workflow
const SkeletonLoadersWorkflow = () => import(
  /* webpackChunkName: "view-SkeletonLoadersWorkflow" */ '../views/SkeletonLoadersWorkflow.vue'
);
// guard for logged in user to not open welcome page

Vue.use(VueRouter);

const loginPageGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    next('/main');
  } else {
    next();
  }
};

const privateRoute = (to, from, next) => {
  if (
    process.env.VUE_APP_API_TARGET === 'development'
    || process.env.VUE_APP_API_TARGET === 'mock'
  ) return next();
  const { fullPath } = to;
  const replacePath = (_path, toIndex) => `/${_path
    .split('/')
    .splice(1, toIndex - 1)
    .toString()
    .replace(/,/g, '/')
    .replace(/\?.*/g, '')}`;
  let selectedMenu;
  const navs = JSON.parse(store.getters['auth/menu']);
  navs.forEach((nav) => {
    if (new RegExp(`^${nav.url}$`).test(replacePath(fullPath, nav.url.split('/').length))) {
      selectedMenu = nav;
    }
    if (nav.child) {
      nav.child.forEach((navChild) => {
        if (
          navChild.url
          && new RegExp(`^${navChild.url}$`).test(
            replacePath(fullPath, navChild.url.split('/').length),
          )
        ) {
          selectedMenu = navChild;
        }
      });
    }
  });
  if (selectedMenu) return next();
  return next(store.getters['auth/defaultRoute']);
};

const authGuard = (to, from, next) => {
  if (store.getters['auth/isLoggedIn']) {
    store
      .dispatch('user/fetchMyInfo')
      .then(() => {
        if (store.getters['user/countAuthGuard'] === 1) store.dispatch('auth/logout', 403);
        next();
        store.dispatch('user/setIncrementCountAuthGuard', 1);
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          store.dispatch('user/setIncrementCountAuthGuard', 0);
          store.dispatch('auth/logout', err.response.status);
        }
      });
  } else {
    store.dispatch('user/setIncrementCountAuthGuard', 0);
    next('/welcome/login');
  }
};

const routes = [
  {
    path: '/welcome/privacy-policy',
    component: PrivacyPage,
    meta: {
      header: 'Ketentuan Privasi',
      title: 'Ketentuan Privasi',
    },
  },
  {
    path: '/welcome',
    component: Welcome,
    beforeEnter: loginPageGuard,
    children: [
      {
        path: '',
        redirect: '/welcome/login',
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: {
          title: 'Masuk',
        },
      },
      {
        path: 'verification',
        name: 'email-verification',
        component: EmailVerification,
        meta: {
          title: 'Verifikasi Email',
        },
      },
      {
        path: 'activation-account',
        name: 'activation-account',
        component: ActivationAccount,
        meta: {
          title: 'Aktifasi Akun',
        },
      },
      {
        path: 'form-registration',
        name: 'form-registration',
        component: FormRegistration,
        meta: {
          title: 'Registration',
        },
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        component: ResetPassword,
        meta: {
          title: 'Reset Password',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          title: 'Ketentuan Privasi',
        },
      },
    ],
  },
  {
    path: '/main',
    component: MainContainer,
    beforeEnter: authGuard,
    children: [
      {
        path: '',
        redirect: store.getters['auth/defaultRoute'],
      },
      {
        path: 'dashboard',
        component: DashboardPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'dashboard-page',
            component: DashboardList,
            meta: {
              headerTitle: 'Dashboard',
              title: 'Dashboard',
            },
          },
          {
            path: 'detail/:groupId',
            name: 'dashboard-detail',
            component: OrderDetail,
            meta: {
              headerTitle: 'Pesanan Detail',
              title: 'Pesanan Detail',
            },
          },
        ],
      },
      {
        path: 'shipments',
        name: 'shipments-page',
        component: ShipmentsPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: 'Pesanan',
          title: 'Pesanan',
        },
      },
      {
        path: 'parameter',
        component: Parameter,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/parameter/list',
          },
          {
            path: 'list',
            name: 'Parameter List',
            component: ParameterList,
            meta: {
              headerTitle: 'Parameter',
              title: 'Parameter List',
            },
          },
          {
            path: 'list-update/:parameterGroupId/:parameterGroupName',
            name: 'List Parameter Update',
            component: ListUpdateParameter,
            meta: {
              headerTitle: 'List Update Parameter',
              action: 'PARAMETER_UPDATE',
              title: 'List Update Parameter',
            },
          },
          {
            path: 'list-update/edit-data/:parameterId/:parameterGroupId',
            name: 'edit-parameter',
            component: EditDataParameter,
            meta: {
              headerTitle: 'Edit Data Parameter',
              action: 'PARAMETER_UPDATE',
              title: 'Edit Data Parameter',
            },
          },
        ],
      },
      {
        path: 'kategori',
        component: CategoriesPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kategori/list',
          },
          {
            path: 'list',
            name: 'category-list-page',
            meta: {
              headerTitle: Strings.categories.PAGE_NAME,
              title: 'List Kategori',
            },
            component: CategoryList,
          },
          {
            path: 'create',
            name: 'category-create-page',
            meta: {
              headerTitle: Strings.categories.CREATE_CATEGORY_PAGE_NAME,
              title: 'Tambah Kategori',
            },
            component: CategoryCreate,
          },
          {
            path: 'edit/:id',
            name: 'category-edit-page',
            meta: {
              headerTitle: Strings.categories.EDIT_CATEGORY_PAGE_NAME,
              title: 'Edit Kategori',
            },
            component: CategoryCreate,
          },
        ],
      },
      {
        path: 'pilihan-alasan',
        component: SelectionInfo,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/pilihan-alasan/list',
          },
          {
            path: 'list',
            name: 'Selection Info',
            component: SelectionInfoList,
            meta: {
              headerTitle: 'Pengaturan Pilihan dan Alasan',
              title: 'Pengaturan Pilihan dan Alasan',
            },
          },
        ],
      },
      {
        path: 'dokumen-legal',
        component: LegalDocumentPage,
        children: [
          {
            path: '',
            redirect: '/main/dokumen-legal/list',
          },
          {
            path: 'list',
            name: 'Document Legal',
            component: LegalDocumentList,
            meta: {
              headerTitle: Strings.legalDocument.PAGE_NAME,
              title: 'List Dokumen Legal',
            },
          },
        ],
      },
      {
        path: 'lokasi',
        component: LocationsPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/lokasi/list',
          },
          {
            path: 'list',
            name: 'location-list-page',
            meta: {
              headerTitle: Strings.locations.PAGE_NAME,
              title: 'List Lokasi',
            },
            component: LocationList,
          },
          {
            path: 'create',
            name: 'location-create-page',
            meta: {
              headerTitle: Strings.locations.CREATE_LOCATION_PAGE_NAME,
              title: 'Tambah Lokasi',
            },
            component: LocationCreate,
          },
          {
            path: 'edit/:id',
            name: 'location-edit-page',
            meta: {
              headerTitle: Strings.locations.EDIT_LOCATION_PAGE_NAME,
              title: 'Edit Lokasi',
            },
            component: LocationCreate,
          },
        ],
      },
      {
        path: 'user-admin',
        component: UserAdminPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/user-admin/list',
          },
          {
            path: 'list',
            name: 'user-admin-list-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'List User Admin',
            },
            component: UserAdminList,
          },
          {
            path: 'create',
            name: 'user-admin-create-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Tambah User Admin',
            },
            component: UserAdminCreate,
          },
          {
            path: 'edit/:companyId/:userId',
            name: 'user-admin-edit-page',
            meta: {
              headerTitle: Strings.userAdmin.PAGE_NAME,
              title: 'Edit User Admin',
            },
            component: UserAdminCreate,
          },
        ],
      },
      {
        path: 'tipe-kendaraan',
        component: TransportTypesPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/tipe-kendaraan/list',
          },
          {
            path: 'list',
            name: 'transport-types-list-page',
            meta: {
              headerTitle: Strings.transportTypes.PAGE_NAME,
              title: 'List Tipe Kendaraan',
            },
            component: TransportTypesList,
          },
          {
            path: 'create',
            name: 'transport-types-create-page',
            meta: {
              headerTitle: Strings.transportTypes.PAGE_NAME,
              title: 'Tambah Kendaraan',
            },
            component: TransportTypesCreate,
          },
          {
            path: 'edit/:id',
            name: 'transport-types-edit-page',
            meta: {
              headerTitle: Strings.transportTypes.PAGE_NAME,
              title: 'Edit Kendaraan',
            },
            component: TransportTypesCreate,
          },
        ],
      },
      {
        path: 'pesanan',
        component: OrderPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/pesanan/list/unconfirmed',
          },
          {
            path: 'list/:status',
            name: 'order-list',
            meta: {
              headerTitle: 'Pesanan',
              title: 'Pesanan',
            },
            component: OrderList,
          },
          {
            path: 'detail/:groupId',
            name: 'order-detail',
            meta: {
              headerTitle: 'Detail Pesanan',
              title: 'Detail Pesanan',
            },
            component: OrderDetail,
          },
          {
            path: 'multimoda/basp',
            name: 'multimoda-basp',
            meta: {
              headerTitle: 'Pesanan',
              title: 'Basp',
            },
            component: MultimodaBasp,
          },
          {
            path: 'history',
            name: 'order-histories',
            meta: {
              headerTitle: 'Pesanan',
              title: 'Pesanan',
            },
            component: OrderHistories,
          },
        ],
      },
      {
        path: 'update-orders',
        component: UpdateOrdersPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/update-orders/list/unconfirmed',
          },
          {
            path: 'list/:status',
            name: 'update-order-list',
            meta: {
              headerTitle: 'Update Pesanan',
              title: 'Update Pesanan',
            },
            component: OrderList,
          },
          {
            path: 'detail/:groupId',
            name: 'update-detail',
            meta: {
              headerTitle: 'Detail Pesanan',
              title: 'Detail Pesanan',
            },
            component: OrderDetail,
          },
          {
            path: ':method/:groupId/:shipmentsId',
            name: 'update-order-detail',
            meta: {
              headerTitle: 'Update Pesanan',
              title: 'Update Pesanan',
            },
            component: OrderDetail,
          },
          {
            path: 'multimoda/basp',
            name: 'update-multimoda-basp',
            meta: {
              headerTitle: 'Pesanan',
              title: 'Basp',
            },
            component: MultimodaBasp,
          },
          {
            path: 'history',
            name: 'update-order-histories',
            meta: {
              headerTitle: 'Pesanan',
              title: 'Pesanan',
            },
            component: OrderHistories,
          },
        ],
      },
      {
        path: 'manual-orders',
        component: ManualOrdersPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'index-manual-orders',
            meta: {
              headerTitle: Strings.manualOrders.PAGE_NAME,
              title: Strings.manualOrders.PAGE_NAME,
            },
            component: ManualOrder,
          },
          {
            path: 'create',
            name: 'create-manual-order',
            meta: {
              headerTitle: Strings.manualOrders.PAGE_NAME,
              title: Strings.manualOrders.PAGE_NAME,
            },
            component: CreateManualOrder,
          },
        ],
      },
      {
        path: 'kontrak-shipper',
        component: ContractShipperPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kontrak-shipper/list',
          },
          {
            path: 'list',
            name: 'contract-shipper-list-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'List Kontrak',
            },
            component: ContractList,
          },
          {
            path: 'template',
            name: 'contract-shipper-template-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Template Kontrak',
            },
            component: TemplateContract,
          },
          {
            path: 'create',
            name: 'contract-shipper-create-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Tambah Kontrak',
            },
            component: ContractForm,
          },
          {
            path: 'view/:contractId',
            name: 'contract-shipper-view-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Detail Kontrak',
            },
            component: ContractForm,
          },
        ],
      },
      {
        path: 'kontrak-3pl',
        component: Contract3PLPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kontrak-3pl/list',
          },
          {
            path: 'list',
            name: 'contract-3pl-list-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'List Kontrak',
            },
            component: ContractList,
          },
          {
            path: 'template',
            name: 'contract-3pl-template-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Template Kontrak',
            },
            component: TemplateContract,
          },
          {
            path: 'create',
            name: 'contract-3pl-create-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Tambah Kontrak',
            },
            component: ContractForm,
          },
          {
            path: 'view/:contractId',
            name: 'contract-3pl-view-page',
            meta: {
              headerTitle: Strings.contract.PAGE_NAME_SHIPPER,
              title: 'Detail Kontrak',
            },
            component: ContractForm,
          },
        ],
      },
      {
        path: 'pengakhiran-kontrak',
        component: TerminationContractPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/pengakhiran-kontrak/list',
          },
          {
            path: 'list',
            name: 'contract-termination-list-page',
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'List Pengakhiran Kontrak',
            },
            component: ContractList,
          },
          {
            path: 'create/:contractId?',
            name: 'contract-termination-create-page',
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'Tambah Pengakhiran Kontrak',
            },
            component: ContractFormTermination,
          },
          {
            path: 'view/:contractId',
            name: 'contract-termination-page',
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'Detail Pengakhiran Kontrak',
            },
            component: ContractFormTermination,
          },
        ],
      },
      {
        path: 'adendum-kontrak',
        component: AdendumContractPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/adendum-kontrak/list',
          },
          {
            path: 'list',
            name: 'adendum-contract-list-page',
            meta: {
              headerTitle: Strings.addendumContract.label.PAGE_NAME,
              title: 'List Adendum Kontrak',
            },
            component: ContractList,
          },
          {
            path: 'template',
            name: 'adendum-contract-template-page',
            meta: {
              headerTitle: Strings.addendumContract.label.ADENDUM,
              title: 'Template Adendum Kontrak',
            },
            component: TemplateAdendum,
          },
          {
            path: 'create/:contractId?',
            name: 'adendum-contract-create-page',
            meta: {
              headerTitle: Strings.addendumContract.label.ADENDUM,
              title: 'Tambah Adendum Kontrak',
            },
            component: ContractForm,
          },
          {
            path: 'view/:contractId',
            name: 'adendum-contract-view-page',
            meta: {
              headerTitle: Strings.addendumContract.label.ADENDUM,
              title: 'Detail Adendum Kontrak',
            },
            component: ContractForm,
          },
        ],
      },
      {
        path: 'mou/shipper',
        component: MoUPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/mou/shipper/list',
          },
          {
            path: 'list',
            name: 'mou-list-page-shipper',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'List MoU',
            },
            component: MoUList,
          },
          {
            path: ':action/:mouId?',
            name: 'shipper-mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'MoU',
            },
            component: MoUCreate,
          },
        ],
      },
      {
        path: 'mou/transporter',
        component: MoUPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/mou/transporter/list',
          },
          {
            path: 'list',
            name: 'mou-list-page-transporter',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'List MoU',
            },
            component: MoUList,
          },
          {
            path: ':action/:mouId?',
            name: 'transporter-mou-detail',
            meta: {
              headerTitle: Strings.mou.MOU,
              title: 'MoU',
            },
            component: MoUCreate,
          },
        ],
      },
      {
        path: 'additional-cost',
        component: AdditionalCost,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/additional-cost/list',
          },
          {
            path: 'list',
            name: 'Additional Cost',
            component: AdditionalCostList,
            meta: {
              headerTitle: Strings.additionalCost.ADDITIONAL_COST_DATABASE,
              action: 'ADDITIONALCOSTS_VIEW',
              title: 'List Biaya Tambahan',
            },
          },
        ],
      },
      {
        path: 'acoute-cost',
        component: AcouteCost,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/acoute-cost/list',
          },
          {
            path: 'list',
            name: 'acoute Cost',
            component: AcouteCostList,
            meta: {
              headerTitle: Strings.acouteCost.ADDITIONAL_COST_DATABASE,
              action: 'ADDITIONALCOSTS_VIEW',
              title: 'List Biaya Angkut',
            },
          },
        ],
      },
      {
        path: 'sales',
        component: SalesPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/sales/list',
          },
          {
            path: 'list',
            name: 'Master Sales',
            component: MasterSalesList,
            meta: {
              headerTitle: Strings.masterSales.TITLE,
              title: 'Master Sales',
            },
          },
        ],
      },
      {
        path: 'oracle-cost',
        component: PageOracleCost,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: Strings.oracleCost.PAGE,
          title: Strings.oracleCost.PAGE,
        },
      },
      {
        path: 'jadwal',
        component: SchedulePage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'schedule-page',
            component: Schedule,
            meta: {
              headerTitle: Strings.schedule.label.PAGE_NAME,
              title: 'Jadwal',
            },
          },
          {
            path: 'detail/:groupId',
            name: 'schedule-detail',
            component: OrderDetail,
            meta: {
              headerTitle: 'Pesanan Detail',
              title: 'Pesanan Detail',
            },
          },
        ],
      },
      {
        path: 'company-registration',
        name: 'company-registration',
        component: CompanyRegistrationPage,
        beforeEnter: privateRoute,
        meta: {
          headerTitle: Strings.companyRegistration.PAGE_NAME,
          title: 'Registrasi Perusahaan',
        },
      },
      {
        path: 'approval-inbox',
        component: InboxApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-inbox/list',
          },
          {
            path: 'list',
            name: 'approval-inbox',
            component: InboxApprovalList,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'List Kotak Masuk',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflow',
            name: 'inbox-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Kotak Masuk (Registrasi Perusahaan)',
            },
          },
          {
            path: 'contract/:id/:defWorkflow',
            name: 'inbox-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Kotak Masuk (Kontrak)',
            },
          },
          {
            path: 'addendum-contract/:id/:defWorkflow',
            name: 'inbox-addendum-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Kotak Masuk (Addendum Kontrak)',
            },
          },
          {
            path: 'contract-termination/:id/:defWorkflow/:contractId?',
            name: 'inbox-contract-termination',
            component: ContractFormTermination,
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'Kotak Masuk (Pengakhiran Kontrak)',
            },
          },
          {
            path: 'mou/:id/:defWorkflow',
            name: 'inbox-mou',
            component: InboxMouInProcess,
            meta: {
              headerTitle: Strings.approval.INBOX,
              title: 'Kotak Masuk (MoU)',
            },
          },
        ],
      },
      {
        path: 'approval-process',
        component: ApprovalInProcessPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/approval-process/list',
          },
          {
            path: 'list',
            name: 'approval-process',
            component: ApprovalInProcessList,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'List Dalam Proses',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflow',
            name: 'process-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dalam Proses (Registrasi Perusahaan)',
            },
          },
          {
            path: 'contract/:id/:defWorkflow',
            name: 'process-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dalam Proses (Kontrak)',
            },
          },
          {
            path: 'addendum-contract/:id/:defWorkflow',
            name: 'process-addendum-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dalam Proses (Addendum Kontrak)',
            },
          },
          {
            path: 'contract-termination/:id/:defWorkflow/:contractId?',
            name: 'process-contract-termination',
            component: ContractFormTermination,
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'Dalam Proses (Pengakhiran Kontrak)',
            },
          },
          {
            path: 'mou/:id/:defWorkflow',
            name: 'process-mou',
            component: InboxMouInProcess,
            meta: {
              headerTitle: Strings.approval.INPROCESS,
              title: 'Dalam Proses (MoU)',
            },
          },
        ],
      },
      {
        path: 'complete-approval',
        component: CompletedApprovalPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/complete-approval/list',
          },
          {
            path: 'list',
            name: 'complete-approval',
            component: CompletedApprovalList,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'List Selesai',
            },
          },
          {
            path: 'company-registration/:id/:defWorkflow',
            name: 'complete-company-registration',
            component: CompanyRegistrationPage,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Selesai (Registrasi Perusahaan)',
            },
          },
          {
            path: 'contract/:id/:defWorkflow',
            name: 'complete-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Selesai (Kontrak)',
            },
          },
          {
            path: 'contract-termination/:id/:defWorkflow',
            name: 'complete-contract-termination',
            component: ContractFormTermination,
            meta: {
              headerTitle: Strings.terminationContract.label.PAGE_NAME,
              title: 'Selesai (Pengakhiran Kontrak)',
            },
          },
          {
            path: 'addendum-contract/:id/:defWorkflow',
            name: 'complete-addendum-contract',
            component: ContractInProcess,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Selesai (Addendum Kontrak)',
            },
          },
          {
            path: 'mou/:id/:defWorkflow',
            name: 'complete-mou',
            component: InboxMouInProcess,
            meta: {
              headerTitle: Strings.approval.COMPLETED,
              title: 'Selesai (MoU)',
            },
          },
        ],
      },
      {
        path: 'approval/:page/:id/:defWorkflow',
        name: 'skeleton-loaders',
        component: SkeletonLoadersWorkflow,
        meta: {
          headerTitle: '',
        },
      },
      {
        path: 'invoice-data',
        component: InvoiceDataPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-data/list',
          },
          {
            path: 'list',
            name: 'Invoice Data',
            component: InvoiceDataList,
            meta: {
              headerTitle: Strings.invoiceData.pageName,
              title: 'List Invoice Data',
            },
          },
        ],
      },
      {
        path: 'about',
        component: AboutPage,
        meta: {
          headerTitle: 'Tentang Kami',
          title: 'Tentang Kami',
        },
      },
      {
        path: 'privacy-policy',
        component: PrivacyPage,
        meta: {
          headerTitle: 'Ketentuan Privasi',
          title: 'Ketentuan Privasi',
        },
      },
      {
        path: 'contact-us',
        component: ContactPage,
        meta: {
          headerTitle: 'Hubungi Kami',
          title: 'Hubungi Kami',
        },
      },
      {
        path: 'change-password',
        component: ChangePassword,
        meta: {
          headerTitle: 'Ubah Kata Sandi',
          title: 'Ubah Kata Sandi',
        },
      },
      {
        path: 'eta',
        component: EtaDataPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/eta/list',
          },
          {
            path: 'list',
            name: 'eta-list',
            component: EtaDataList,
            meta: {
              headerTitle: 'Master ETA',
              title: 'Master ETA',
            },
          },
          {
            path: 'create',
            name: 'eta-create-page',
            component: EtaDataCreate,
            meta: {
              headerTitle: 'Tambah ETA',
              title: 'Tambah ETA',
            },
          },
          {
            path: 'edit/:id',
            name: 'eta-edit',
            component: EtaDataCreate,
            meta: {
              headerTitle: 'Edit ETA',
              title: 'Edit ETA',
            },
          },
        ],
      },
      {
        path: 'shipping-cost',
        component: ShippingCost,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/shipping-cost/list',
          },
          {
            path: 'list',
            name: 'Shipping Cost',
            component: ShippingCostList,
            meta: {
              headerTitle: Strings.shippingCost.shippingCostDatabase,
              title: 'List Biaya Ongkos Kirim',
            },
          },
        ],
      },
      {
        path: 'dokumen-pod',
        component: DokumenPodPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/dokumen-pod/list',
          },
          {
            path: 'list',
            name: 'dokumenPod-list',
            component: DokumenPodList,
            meta: {
              headerTitle: 'Dokumen Hard Copy POD',
              title: 'List Dokumen POD',
            },
          },
          {
            path: 'create',
            name: 'dokumen-pod-create-page',
            component: DokumenPodCreate,
            meta: {
              headerTitle: 'Tambah Dokumen POD',
              title: 'Tambah Dokumen POD',
            },
          },
          {
            path: 'edit/:cargoId',
            name: 'dokumen-pod-edit-page',
            component: DokumenPodCreate,
            meta: {
              headerTitle: 'Dokumen POD',
              title: 'Edit Dokumen POD',
            },
          },
        ],
      },
      {
        path: 'email',
        component: Email,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/email/list',
          },
          {
            path: 'list',
            name: 'Email',
            component: EmailList,
            meta: {
              headerTitle: Strings.email.emailDatabase,
              title: 'List Email',
            },
          },
        ],
      },
      {
        path: 'rekanan',
        component: MasterPartnerAndShipperPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/rekanan/list',
          },
          {
            path: 'list',
            name: 'rekanan',
            meta: {
              headerTitle: 'Rekanan 3PL',
              title: 'List Rekanan 3PL',
            },
            component: MasterPartnerAndShipperList,
          },
          {
            path: 'detail/:companyId/:mouId?',
            meta: {
              headerTitle: 'Master Rekanan',
              title: 'Master Rekanan',
            },
            component: MasterPartnerAndShipperDetail,
          },
          {
            path: 'edit/:companyId/:mouId?',
            meta: {
              headerTitle: 'Master Rekanan',
              title: 'Tambah Master Rekanan',
            },
            component: MasterPartnerAndShipperDetail,
          },
        ],
      },
      {
        path: 'customer',
        component: MasterPartnerAndShipperPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/customer/list',
          },
          {
            path: 'list',
            name: 'customer',
            meta: {
              headerTitle: 'Master Shipper',
              title: 'Master Shipper',
            },
            component: MasterPartnerAndShipperList,
          },
          {
            path: 'detail/:companyId/:mouId?',
            meta: {
              headerTitle: 'Master Shipper',
              title: 'Detail Master Shipper',
            },
            component: MasterPartnerAndShipperDetail,
          },
          {
            path: 'edit/:companyId/:mouId?',
            meta: {
              headerTitle: 'Master Shipper',
              title: 'Edit Master Shipper',
            },
            component: MasterPartnerAndShipperDetail,
          },
        ],
      },
      {
        path: 'master-customer',
        component: MasterCustomerPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/master-customer/list',
          },
          {
            path: 'list',
            name: 'master-customer',
            meta: {
              headerTitle: 'Master Customer',
              title: 'Master Customer',
            },
            component: MasterCustomerList,
          },
        ],
      },
      {
        path: 'kerusakan',
        component: DamageAndMissing,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/kerusakan/list',
          },
          {
            path: 'list',
            name: 'damage-and-missing-page',
            component: DamageAndMissingList,
            meta: {
              headerTitle: 'Kerusakan Dan Selisih',
              title: 'Kerusakan Dan Selisih',
            },
          },
        ],
      },

      {
        path: 'masalah',
        component: ShippingProblemPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/masalah/list/unconfirmed',
          },
          {
            path: 'list/:status',
            name: 'masalah-list',
            meta: {
              headerTitle: 'Masalah Pengiriman',
              title: 'Masalah Pengiriman',
            },
            component: ShippingProblemList,
          },
          {
            path: 'detail/:id',
            name: 'masalah-detail',
            meta: {
              headerTitle: 'Detail Masalah Pengiriman',
              title: 'Detail Masalah Pengiriman',
            },
            component: ShippingProblemDetail,
          },
        ],
      },
      {
        path: 'invoice-transporter',
        component: Invoice3PL,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-transporter/list',
          },
          {
            path: 'list',
            name: 'invoice-list-transporter',
            meta: {
              headerTitle: 'Invoice Rekanan 3PL',
              title: 'List Invoice',
            },
            component: InvoiceList,
          },
        ],
      },
      {
        path: 'invoice-customer',
        component: InvoiceShipper,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-customer/list',
          },
          {
            path: 'list',
            name: 'invoice-list-customer',
            meta: {
              headerTitle: 'Invoice Customer',
              title: 'List Invoice Customer',
            },
            component: InvoiceShipperList,
          },
          {
            path: 'create',
            name: 'invoice-create',
            meta: {
              headerTitle: 'Invoice Customer',
              title: 'Tambah Invoice Customer',
            },
            component: InvoiceCreate,
          },
          {
            path: 'edit/:invoiceId',
            name: 'invoice-edit',
            meta: {
              headerTitle: 'Ubah Invoice',
              title: 'Ubah Invoice',
            },
            component: InvoiceEdit,
          },
        ],
      },
      {
        path: 'invoice-transporter-fixed-price',
        component: InvoiceFixedPrice,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: 'list/transporter',
          },
          {
            path: 'list/:companyType',
            name: 'invoice-list-transporter-fixed-price',
            meta: {
              headerTitle: 'Invoice Rekanan Fixed Price',
              title: 'List Invoice Rekanan Fixed Price',
            },
            component: InvoiceFixedPriceList,
          },
          {
            path: 'list-detail/:companyType/:invoiceId',
            name: 'invoice-list-detail-transporter-fixed-price',
            meta: {
              headerTitle: 'Invoice Rekanan Fixed Price',
              title: 'List Detail Invoice Rekanan Fixed Price',
            },
            component: InvoiceFixedPriceListDetail,
          },
        ],
      },
      {
        path: 'invoice-customer-fixed-price',
        component: InvoiceFixedPrice,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: 'list/shipper',
          },
          {
            path: 'list/:companyType',
            name: 'invoice-list-customer-fixed-price',
            meta: {
              headerTitle: 'Invoice Customer Fixed Price',
              title: 'List Invoice Customer Fixed Price',
            },
            component: InvoiceFixedPriceList,
          },
          {
            path: 'list-detail/:companyType/:invoiceId',
            name: 'invoice-list-detail-customer-fixed-price',
            meta: {
              headerTitle: 'Invoice Customer Fixed Price',
              title: 'List Detail Invoice Customer Fixed Price',
            },
            component: InvoiceFixedPriceListDetail,
          },
          {
            path: 'list-detail/invoice/:companyType/:invoiceId',
            name: 'invoice-fixed-price-list-price-detail',
            meta: {
              headerTitle: 'Invoice Customer Fixed Price',
              title: 'List Detail Invoice Customer Fixed Price',
            },
            component: InvoiceFixedPriceListPriceDetail,
          },
        ],
      },
      {
        path: 'invoice-cancel',
        component: InvoiceCustomerCancel,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: 'list',
          },
          {
            path: 'list',
            name: 'invoice-cancel-list',
            meta: {
              headerTitle: 'Invoice Customer Cancel',
              title: 'List Invoice Customer Cancel',
            },
            component: InvoiceCustomerCancelList,
          },
          {
            path: ':invoiceId',
            name: 'invoice-cancel-detail',
            meta: {
              headerTitle: 'Invoice Customer Cancel',
              title: 'Detail Invoice Customer Cancel',
            },
            component: InvoiceCustomerCancelDetail,
          },
          {
            path: ':invoiceId/create',
            name: 'invoice-cancel-create',
            meta: {
              headerTitle: 'Invoice Customer Cancel',
              title: 'Create Invoice Customer Cancel',
            },
            component: InvoiceCustomerCancelCreate,
          },
        ],
      },
      {
        path: 'invoice-customer-fixed-price-cancel',
        component: InvoiceFixedPriceCustomerCancel,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: 'list',
          },
          {
            path: 'list',
            name: 'invoice-customer-fixed-price-cancel-list',
            meta: {
              headerTitle: 'Invoice Fixed Price Customer Cancel',
              title: 'List Invoice Customer Cancel',
            },
            component: InvoiceFixedPriceCustomerCancelList,
          },
          {
            path: ':invoiceId',
            name: 'invoice-customer-fixed-price-cancel-detail',
            meta: {
              headerTitle: 'Invoice Fixed Price Customer Cancel',
              title: 'Detail Fixed Price Invoice Customer Cancel',
            },
            component: InvoiceFixedPriceCustomerCancelDetail,
          },
        ],
      },
      {
        path: 'vaccine',
        component: Vaccine,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/vaccine/list',
          },
          {
            path: 'list',
            name: 'vaccine-list',
            meta: {
              headerTitle: 'Vaksin',
              title: 'List Vaksin',
            },
            component: VaccineList,
          },
        ],
      },
      {
        path: 'area-code',
        component: AreaCode,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/area-code/list',
          },
          {
            path: 'list',
            name: 'area-code-list',
            meta: {
              headerTitle: 'Kode Area',
              title: 'List Kode Area',
            },
            component: AreaCodeList,
          },
        ],
      },
      {
        path: 'pajak',
        component: TaxPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/pajak/list',
          },
          {
            path: 'list',
            name: 'pajak-list',
            meta: {
              headerTitle: 'Pajak (e-Faktur)',
              title: 'Pajak (e-Faktur)',
            },
            component: TaxList,
          },
        ],
      },
      {
        path: 'account',
        component: AccountInfo,
        children: [
          {
            path: '',
            redirect: '/main/account/info',
          },
          {
            path: 'info',
            name: 'account-info',
            meta: {
              headerTitle: '',
              title: 'Info Akun',
            },
            component: AccountInfoGet,
          },
        ],
      },
      {
        path: 'term-of-payment',
        component: TopPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/term-of-payment/list',
          },
          {
            path: 'list',
            name: 'top-list',
            component: TopList,
            meta: {
              headerTitle: 'Term Of Payment',
              title: 'Term Of Payment',
            },
          },
          {
            path: 'create',
            name: 'top-create-page',
            component: TopCreate,
            meta: {
              headerTitle: 'Tambah Term Of Payment',
              title: 'Tambah Term Of Payment',
            },
          },
          {
            path: 'edit/:id',
            name: 'top-edit',
            component: TopCreate,
            meta: {
              headerTitle: 'Edit Term Of Payment',
              title: 'Edit Term Of Payment',
            },
          },
        ],
      },
      {
        path: 'laporan',
        component: ReportPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/laporan/list',
          },
          {
            path: 'list',
            name: 'report-list',
            component: ReportList,
            meta: {
              headerTitle: 'Laporan',
              title: 'Laporan',
            },
          },
          {
            path: 'filter/:id',
            name: 'report-filter',
            meta: {
              title: 'Parameter Laporan',
            },
            component: ReportFiltres,
          },
          {
            path: 'detail/:id',
            name: 'report-detail',
            meta: {
              title: 'Detail Laporan',
            },
            component: ReportDetail,
          },
        ],
      },
      {
        path: 'wording',
        component: Wording,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/wording/list',
          },
          {
            path: 'list',
            name: 'Wording List',
            component: WordingList,
            meta: {
              headerTitle: 'Wording',
              title: 'Wording List',
            },
          },
          {
            path: 'edit/:wordingId',
            name: 'edit-wording',
            component: EditWording,
            meta: {
              headerTitle: 'Edit Wording',
              title: 'Edit Wording',
            },
          },
        ],
      },
      {
        path: 'p2p-lending',
        component: PageP2PLending,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/p2p-lending/list',
          },
          {
            path: 'list',
            name: 'p2p-lending',
            component: ListP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'P2P Lending',
            },
          },
          {
            path: 'edit/:id',
            name: 'edit-p2p-lending',
            component: CreateP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'Edit P2P Lending',
            },
          },
          {
            path: 'detail/:id',
            name: 'detail-p2p-lending',
            component: CreateP2PLending,
            meta: {
              headerTitle: Strings.p2pLending.PAGE_TITLE,
              title: 'Detail P2P Lending',
            },
          },
        ],
      },
      {
        path: 'holiday',
        component: PageHoliday,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/holiday/list',
          },
          {
            path: 'list',
            name: 'holiday',
            component: HolidayList,
            meta: {
              headerTitle: Strings.holiday.PAGE_NAME,
              title: Strings.holiday.PAGE_NAME,
            },
          },
        ],
      },
      {
        path: 'close-period',
        component: PageClosePeriode,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/close-period/list',
          },
          {
            path: 'list',
            name: 'close-period',
            component: ClosePeriodeList,
            meta: {
              headerTitle: Strings.closePeriod.PAGE_NAME,
              title: Strings.closePeriod.PAGE_NAME,
            },
          },
          {
            path: 'setting-close-period',
            name: 'setting-close-period',
            component: SettingClosePeriode,
            meta: {
              headerTitle: Strings.closePeriod.ADD_CLOSE_PERIODE,
              title: Strings.closePeriod.ADD_CLOSE_PERIODE,
            },
          },
        ],
      },
      {
        path: 'accrual-oracle',
        component: AccrualOraclePage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            component: AccrualOracleList,
            meta: {
              headerTitle: 'Accrual Oracle',
              title: 'Accrual Oracle',
            },
          },
        ],
      },
      {
        path: 'accrual-from-oracle',
        component: AccrualFromOraclePage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            component: AccrualFromOracleList,
            meta: {
              headerTitle: 'Accrual From Oracle',
              title: 'Accrual From Oracle',
            },
          },
        ],
      },
      {
        path: 'invoice-from-oracle',
        component: InvoiceFromOraclePage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            component: InvoiceFromOracleList,
            meta: {
              headerTitle: 'Invoice From Oracle',
              title: 'Invoice From Oracle',
            },
          },
        ],
      },
      {
        path: 'accrual-gl',
        component: PageOracleAccrualGL,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/accrual-gl/oncall',
          },
          {
            path: ':type',
            name: 'oracle-accrual-gl',
            component: OracleAccrualGL,
            meta: {
              headerTitle: Strings.oracleData.ACCRUAL_GL,
              title: Strings.oracleData.ACCRUAL_GL,
            },
          },
        ],
      },
      {
        path: 'invoice-oracle',
        component: PageOracleInvoice,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/invoice-oracle/REGULAR',
          },
          {
            path: ':type',
            name: 'invoice-oracle',
            component: OracleInvoice,
            meta: {
              headerTitle: Strings.oracleData.INVOICE_ORACLE,
              title: Strings.oracleData.INVOICE_ORACLE,
            },
          },
        ],
      },
      {
        path: 'oracle-location',
        component: PageOracleLocation,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'list-oracle-location',
            component: ListOracleLocation,
            meta: {
              headerTitle: 'Lokasi Oracle',
              title: 'Lokasi Oracle',
            },
          },
        ],
      },
      {
        path: 'role',
        component: PageRole,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'list-role',
            component: ListRole,
            meta: {
              headerTitle: 'Role',
              title: 'Role',
            },
          },
          {
            path: ':roleId',
            name: 'detail-role',
            component: DetailRole,
            meta: {
              headerTitle: 'Role User - Permission',
              title: 'Role User - Permission',
            },
          },
        ],
      },
      {
        path: 'master-menu',
        component: PageMasterMenu,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'list-master-menu',
            component: ListMasterMenu,
            meta: {
              headerTitle: 'Master Menu',
              title: 'Master Menu',
            },
          },
        ],
      },
      {
        path: 'oracle-orders',
        component: PageOracleOrders,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/oracle-orders/WAITING',
          },
          {
            path: ':status',
            name: 'list-oracle-Orders',
            component: ListOracleOrders,
            meta: {
              headerTitle: 'Pesanan Oracle',
              title: 'Pesanan Oracle',
            },
          },
        ],
      },
      {
        path: 'rejected-orders',
        component: PageRejectedOrders,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            name: 'list-rejected-Orders',
            component: ListRejectedOrders,
            meta: {
              headerTitle: 'Pesanan Ditolak',
              title: 'Pesanan Ditolak',
            },
          },
        ],
      },
      {
        path: 'driver',
        component: DriverPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/driver/list',
          },
          {
            path: 'list',
            name: 'driver-list',
            meta: {
              headerTitle: 'Sopir',
              title: 'Sopir',
            },
            component: DriverList,
          },
          {
            path: 'create/:companyId/:driverId?',
            name: 'driver-create',
            meta: {
              headerTitle: Strings.driver.CREATE_DRIVER_PAGE_NAME,
              title: 'Tambah Sopir',
            },
            component: DriverCreate,
          },
          {
            path: 'edit/:companyId/:driverId',
            name: 'edit-driver',
            meta: {
              headerTitle: Strings.driver.EDIT_DRIVER_PAGE_NAME,
              title: 'Edit Sopir',
            },
            component: DriverCreate,
          },
          {
            path: 'detail/:companyId/:driverId',
            name: 'detail-driver',
            meta: {
              headerTitle: Strings.driver.DETAIL_DRIVER,
              title: 'Detail Sopir',
            },
            component: DriverCreate,
          },
        ],
      },
      {
        path: 'transport',
        component: TransportPage,
        beforeEnter: privateRoute,
        children: [
          {
            path: '',
            redirect: '/main/transport/list',
          },
          {
            path: 'list',
            name: 'transport-list',
            meta: {
              headerTitle: 'Kendaraan',
              title: 'Kendaraan',
            },
            component: TransportList,
          },
          {
            path: 'create/:companyId',
            name: 'transport-create',
            meta: {
              headerTitle: 'Tambah Kendaraan',
              title: 'Tambah Kendaraan',
            },
            component: TransportCreate,
          },
          {
            path: 'edit/:companyId/:transportId',
            name: 'transport-edit-page',
            meta: {
              headerTitle: 'Edit Kendaraan',
              title: 'Edit Kendaraan',
            },
            component: TransportCreate,
          },
        ],
      },
      {
        path: 'empty-page',
        component: PageDontHavePermission,
        beforeEnter: privateRoute,
      },
      {
        path: '/*',
        redirect: store.getters['auth/defaultRoute'],
      },
    ],
  },
  {
    path: '*',
    beforeEnter: authGuard,
    redirect: '/welcome/login',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  document.title = to.meta.title || 'FLI Admin Dashboard';
  next();
});

export default router;
